import React, { Component } from "react";

// css
import "./css/style.css";

// libraries
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// component
import SideNav from "./component/SideNav";
import TopHead from "./component/TopHead";
import Home from "./pages/Home";

// react-toastify
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import Articles from "./pages/Articles";
import AddArticle from "./pages/AddArticle";
import EditArticle from "./pages/EditArticle";
import Services from "./pages/Services";
import AddService from "./pages/AddService";
import EditService from "./pages/EditService";
import HistoryWorks from "./pages/HistoryWorks";
import AddHistoryWork from "./pages/AddHistoryWork";
import EditHistoryWork from "./pages/EditHistoryWork";
import Settings from "./pages/Settings";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";

class App extends Component {
  state = {
    login: true,
  };


  getToken(){
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    console.log(userToken);
    const token =  userToken===null? false:true
    return token;
  }

  render() {
    const openMobSideMenu = () => {
      document.querySelector(".right-sidebar").style.right = 0;
      document
        .querySelector(".bg-sidenavOpen")
        .classList.add("bg-sidenavOpenactive");
      document.body.classList.add("openMenuActive");
    };

    const closeSideMenue = () => {
      var sidebar = (document.querySelector(".right-sidebar").style.right =
        "-300px");
      document
        .querySelector(".bg-sidenavOpen")
        .classList.remove("bg-sidenavOpenactive");
      document.body.classList.remove("openMenuActive");
    };

    const removeSide = (e) => {
      document.querySelector(".right-sidebar").style.right = "-300px";
      e.target.classList.remove("bg-sidenavOpenactive");
      document.body.classList.remove("openMenuActive");
    };

    
    const token = this.getToken();
    if (!token) {
      return (
        <>
          <ToastContainer autoClose={5000} icon={true} />
          <Router>
            <Login />
          </Router>
        </>
      );
    }

    return (
      <>
        <ToastContainer autoClose={5000} icon={true} />
        <Router>
          <div className="bg-sidenavOpen" onClick={removeSide}></div>
          <SideNav closeSideMenue={closeSideMenue} />
          <TopHead openMobSideMenu={openMobSideMenu} />
          <div className="App">
            <Routes>
              <Route exact path="/articles" element={<Articles />} />
              <Route
                exact
                path="/articles/add-article"
                element={<AddArticle />}
              />
              <Route
                exact
                path="/articles/edit-article/:id"
                element={<EditArticle />}
              />
              <Route exact path="/services" element={<Services />} />
              <Route
                exact
                path="/services/add-service"
                element={<AddService />}
              />
              <Route
                exact
                path="/services/edit-service/:id"
                element={<EditService />}
              />
              <Route exact path="/historyworks" element={<HistoryWorks />} />
              <Route
                exact
                path="/historyworks/add-historywork"
                element={<AddHistoryWork />}
              />
              <Route
                exact
                path="/historyworks/edit-historywork/:id"
                element={<EditHistoryWork />}
              />
              <Route exact path="/contcat-us" element={<ContactUs />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/" element={<Home />} />
            </Routes>
          </div>
        </Router>
      </>
    );
  }
}

export default App;

// closeSideMenue={closeSideMenue}
// openMobSideMenu={openMobSideMenu}
