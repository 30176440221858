import React, { Component } from "react";
import ReactDOM from "react-dom";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import TextEditor from "../component/TextEditor";
import { Routes, useParams, useNavigate } from "react-router-dom";

// component
import { BASE_URL } from "../component/BASE_URL";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

import GetUserToken from "../component/GetUserToken";
import DashboardHeader from "../component/DashboardHeader";
const token = GetUserToken();
const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class AddArticle extends Component {
  state = {
    title: "",
    description: "",
    type: "article",
    date: "",
    image_file: "",
    editorState: "",
    loading: false,
  };

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    const html = "<p> Type <strong>Here</strong></p>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
    }
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  handelUploadPicture = (e) => {
    this.setState({
      // picturePreview: URL.createObjectURL(e.target.files[0]),
      image_file: e.target.files[0],
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({
      loading: true,
    });
    if (this.validator.allValid()) {
      const state = { ...this.state };
      delete state.loading;
      delete state.textvalue;
      delete state.editorState;
      const formData = new FormData();
      formData.append("image_file", this.state.image_file);
      Object.keys(state).forEach((key) => {
        formData.append(key, this.state[key]);
      });
      console.log(formData);
      axios
        .post(`${BASE_URL}articles`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status == 200) {
            this.setState({
              loading: false,
            });
            toast.success("تم الاضافه بنجاح");
            this.props.navigate("/articles");
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.message}`);
          this.setState({
            loading: false,
          });
        });
    } else {
      toast.error("هناك بعض الاخطاء");
      this.validator.showMessages();
      this.setState({
        loading: false,
      });
      this.forceUpdate();
    }
  };

  render() {
    const { loading, textvalue } = this.state;
    return (
      <div className="main-content">
        <DashboardHeader />
        <div className="dashboard-content">
          <div className="dasboard-box">
            <div className="dashboard_title mb-3">
              <h5>اضافه مقاله</h5>
            </div>
            <div className="formadd">
              <form action="" onSubmit={this.submitForm}>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="thetitle">الاسم</label>
                      <input
                        type="text"
                        name="title"
                        id="thetitle"
                        className="form-control"
                        placeholder="الاسم"
                        onChange={this.handelChange}
                      />
                      <span className="error">
                        {this.validator.message(
                          "title",
                          this.state.title,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="thedate">التاريخ</label>
                      <input
                        type="date"
                        name="date"
                        id="thedate"
                        className="form-control"
                        placeholder="التاريخ"
                        onChange={this.handelChange}
                      />
                      <span className="error">
                        {this.validator.message(
                          "date",
                          this.state.date,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="thimage">الصورة</label>
                      <input
                        type="file"
                        name="image_file"
                        id="thimage"
                        className="form-control"
                        placeholder="الصورة"
                        onChange={this.handelUploadPicture}
                      />
                      <span className="error">
                        {this.validator.message(
                          "image",
                          this.state.image_file,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label htmlFor="thedescription">الوصف</label>

                      <TextEditor
                        onEditorStateChange={this.onEditorStateChange}
                        editorState={this.state.editorState}
                      />

                      <span className="error">
                        {this.validator.message(
                          "description",
                          this.state.description,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-12">
                    <div className="btn-submit">
                      <button className="btn" type="submit">
                        اضافه
                      </button>
                      <button className="btn btn-danger" type="reset">
                        الغاء
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {loading ? (
              <div className="loading">
                <div className="circles">
                  <div className="circle1"></div>
                  <div className="circle2"></div>
                  <div className="circle3"></div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddArticle);
