import React, { Component } from "react";

class TopHead extends Component {
  render() {
    const {openMobSideMenu} = this.props
    return (
      <div>
          <div className="top-head">
            <div className="container">
              <button className="btn" onClick={openMobSideMenu}><i className="fas fa-bars"></i></button>
            </div>
          </div>
      </div>
    );
  }
}

export default TopHead;
