import axios from "axios";
import React, { Component } from "react";
import { BASE_URL } from "./BASE_URL";
import { Dropdown, Modal, Button } from "react-bootstrap";
import GetUserToken from "../component/GetUserToken";
import { toast } from "react-toastify";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

const token = GetUserToken();

const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class DashboardHeader extends Component {
  state = {
    data: {},
    name: "",
    user_name: "",
    password: "",
    show: false,
    loading: true,
  };

  componentDidMount = () => {
    axios
      .get(`${BASE_URL}account`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log(res);
          this.setState({
            data: res.data.data,
            user_name: res.data.data.user_name,
            name: res.data.data.name,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  logOut = () => {
    sessionStorage.removeItem("token");
    window.location.reload();
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const state = { ...this.state };
    delete state.loading;
    delete state.show;
    delete state.data;
    if (state.password == "") {
      delete state.password;
    }
    // console.log(token , state)
    axios
      .put(`${BASE_URL}account`, state, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          // toast
          toast.success(`تم التعديل بنجاح`);
          setTimeout(() => {
            this.setState({
              loading: false,
              show: false,
            });
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({
          loading: false,
        });
      });
  };

  showPass=(e)=>{
    e.preventDefault();
    document.getElementById("passwordInput").setAttribute("type", "text");  
    // console.log(e.target);
    // e.target.style.display = "none";
    // document.querySelector(".hidepass").style.display = "block";

  }

    // hidePass=(e)=>{
    //   e.preventDefault();
    //   document.getElementById("passwordInput").setAttribute("type", "password");  
    //   e.target.style.display = "none";
    //   document.querySelector(".showpass").style.display = "block";
    // }

  render() {
    const { data, show, user_name, name, loading } = this.state;
    return (
      <div className="dashboard-header">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {data.user_name}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={this.handleShow}>
              <i class="fa-solid fa-gear"></i> <span>الاعدادات</span>{" "}
            </Dropdown.Item>
            <button className="btnlog dropdown-item" onClick={this.logOut}>
              <i className="fas fa-sign-out-alt"></i> <span>تسجيل الخروج</span>{" "}
            </button>
          </Dropdown.Menu>
        </Dropdown>

        <Modal show={show} onHide={this.handleClose} className="editModal">
          <Modal.Header closeButton>
            <Modal.Title>الاعدادات</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="" className="formStyle" onSubmit={this.submitForm}>
              <div className="form-group">
                <label htmlFor="">اسم المستخدم</label>
                <input
                  type="text"
                  name="user_name"
                  id=""
                  className="form-control"
                  onChange={this.handelChange}
                  value={user_name}
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="">اسم المستخدم</label>
                <input
                  type="text"
                  name="name"
                  id=""
                  className="form-control"
                  onChange={this.handelChange}
                  value={name}
                />
              </div> */}
              <div className="form-group">
                <label htmlFor="">كلمة المرور</label>
                <div class="input-group ">
                  <input
                    type="password"
                    name="password"
                    id="passwordInput"
                    onChange={this.handelChange}
                    className="form-control"
                  />
                  <span class="input-group-text" id="basic-addon1">
                    <button className="passoption showpass" onClick={this.showPass}>
                      <i class="fa-solid fa-eye"></i>
                    </button>
                    {/* <button className="passoption hidepass" onClick={this.hidePass}>
                      <i class="fa-solid fa-eye-slash"></i>
                    </button> */}
                  </span>
                </div>
              </div>
              <div className="option_button">
                <button className="btn btn-success" type="submit">
                  حفظ
                </button>
                <button className="btn btn-danger" type="reset" onClick={this.handleClose}>
                  الغاء
                </button>
              </div>
            </form>
          </Modal.Body>
          {loading ? (
            <div className="loading">
              <div className="circles">
                <div className="circle1"></div>
                <div className="circle2"></div>
                <div className="circle3"></div>
              </div>
            </div>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default withRouter(DashboardHeader);
