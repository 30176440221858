import React, { Component } from "react";
import axios from "axios";
import { BASE_URL } from "../component/BASE_URL";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import DashboardHeader from "../component/DashboardHeader";
import GetUserToken from "../component/GetUserToken";
const token = GetUserToken();

class Settings extends Component {
  state = {
    address: "",
    description: "",
    email: "",
    instagram: "",
    phone: "",
    phone2: "",
    title: "",
    twitter: "",
    video: "",
    video2: "",
    whatsapp: "",
    facebook: "",
    youtube: "",
    loading: true,
  };

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.retreveData();
  }

  retreveData = () => {
    axios
      .get(`${BASE_URL}configrations/1`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res);
          this.setState({
            loading: false,
            address: res.data.data.address,
            description: res.data.data.description,
            email: res.data.data.email,
            instagram: res.data.data.instagram,
            phone: res.data.data.phone,
            phone2: res.data.data.phone2,
            title: res.data.data.title,
            twitter: res.data.data.twitter,
            video: res.data.data.video,
            video2: res.data.data.video2,
            whatsapp: res.data.data.whatsapp,
            facebook: res.data.data.facebook,
            youtube: res.data.data.youtube,
          });
        }
      });
  };

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    if (this.validator.allValid()) {
      const state = { ...this.state };
      delete state.loading;
      axios
        .put(`${BASE_URL}configrations/1`, state, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.status == 200) {
            this.setState({
              loading: false,
            });
            toast.success("تم التعديل بنجاح");
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.message}`);
          this.setState({
            loading: false,
          });
        });
    } else {
      toast.error("هناك بعض الاخطاء");
      this.validator.showMessages();
      this.setState({
        loading: false,
      });
      this.forceUpdate();
    }
  };

  render() {
    const {
      loading,
      address,
      description,
      email,
      instagram,
      phone,
      phone2,
      title,
      twitter,
      video,
      video2,
      whatsapp,
      facebook,
      youtube,
    } = this.state;
    return (
      <div>
        <div className="main-content">
          <DashboardHeader />
          <div className="dashboard-content">
            <div className="dasboard-box">
              <div className="dashboard_title mb-3">
                <h5>الاعدادات</h5>
              </div>
              <div className="formadd">
                <form action="" onSubmit={this.submitForm}>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thetitle">الاسم</label>
                        <input
                          type="text"
                          name="title"
                          id="thetitle"
                          value={title}
                          className="form-control"
                          placeholder="الاسم"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "title",
                            this.state.title,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="theaddress">العنوان</label>
                        <input
                          type="text"
                          name="address"
                          id="theaddress"
                          value={address}
                          className="form-control"
                          placeholder="العنوان"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "address",
                            this.state.address,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="theemail">البريد الالكتروني</label>
                        <input
                          type="email"
                          name="email"
                          id="theemail"
                          value={email}
                          className="form-control"
                          placeholder="البريد الالكتروني"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="theinstagram">رابط انستجرام</label>
                        <input
                          type="text"
                          name="instagram"
                          id="theinstagram"
                          value={instagram}
                          className="form-control"
                          placeholder="رابط انستجرام"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "instagram",
                            this.state.instagram,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thephone">الهاتف</label>
                        <input
                          type="number"
                          name="phone"
                          id="thephone"
                          value={phone}
                          className="form-control"
                          placeholder="الهاتف"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "phone",
                            this.state.phone,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thephone2">هاتف اخر</label>
                        <input
                          type="number"
                          name="phone2"
                          id="thephone2"
                          value={phone2}
                          className="form-control"
                          placeholder="هاتف اخر"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "phone2",
                            this.state.phone2,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thetwitter">رابط تويتر</label>
                        <input
                          type="number"
                          name="twitter"
                          id="thetwitter"
                          value={twitter}
                          className="form-control"
                          placeholder="رابط تويتر"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "twitter",
                            this.state.twitter,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thefacebook">رابط فيسبوك</label>
                        <input
                          type="text"
                          name="facebook"
                          id="thefacebook"
                          value={facebook}
                          className="form-control"
                          placeholder="رابط فيسبوك"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "facebook",
                            this.state.facebook,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thevideo">رابط فيديو</label>
                        <input
                          type="text"
                          name="video"
                          id="thevideo"
                          value={video}
                          className="form-control"
                          placeholder="رابط فيديو"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "video",
                            this.state.video,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thevideo2">رابط فيديو اخر</label>
                        <input
                          type="text"
                          name="video2"
                          id="thevideo2"
                          value={video2}
                          className="form-control"
                          placeholder="رابط فيديو اخر"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "video2",
                            this.state.video2,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="theyoutube">رابط اليوتيوب</label>
                        <input
                          type="text"
                          name="youtube"
                          id="theyoutube"
                          value={youtube}
                          className="form-control"
                          placeholder="رابط اليوتيوب"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "youtube",
                            this.state.youtube,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="thewhatsapp">رقم الواتساب</label>
                        <input
                          type="text"
                          name="whatsapp"
                          id="thewhatsapp"
                          value={whatsapp}
                          className="form-control"
                          placeholder="رقم الواتساب"
                          onChange={this.handelChange}
                        />
                        <span className="error">
                          {this.validator.message(
                            "whatsapp",
                            this.state.whatsapp,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label htmlFor="thedescription">وصف</label>
                        <textarea
                          type="text"
                          name="description"
                          id="thedescription"
                          value={description}
                          className="form-control"
                          placeholder="وصف"
                          onChange={this.handelChange}
                        ></textarea>
                        <span className="error">
                          {this.validator.message(
                            "description",
                            this.state.description,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-12">
                      <div className="btn-submit">
                        <button className="btn" type="submit">
                          حفظ
                        </button>
                        <button
                          className="btn btn-danger"
                          type="reset"
                          onClick={() => this.props.navigate("/historyworks")}
                        >
                          الغاء
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {loading ? (
                <div className="loading">
                  <div className="circles">
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                    <div className="circle3"></div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
