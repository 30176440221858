import React, { Component } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

// mdreact
import { MDBDataTableV5 } from "mdbreact";

// react router dom
import { Link } from "react-router-dom";

// sweetalert2t
import Swal from "sweetalert2";
// toast
import { toast } from "react-toastify";

// component
import { BASE_URL, Bublic_URL } from "../component/BASE_URL";
import axios from "axios";
import GetUserToken from "../component/GetUserToken";
import DashboardHeader from "../component/DashboardHeader";


const token = GetUserToken();

const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class ContactUs extends Component {
  state = {
    show: false,
    date: [],
    loading: true,
    rowDate: {},
  };

  componentDidMount() {
    this.changePlaceHolderValue();
    this.retreveDate();
  }

  changePlaceHolderValue = () => {
    document
      .querySelector(".form-control")
      .setAttribute("placeholder", "ابحث هنا");
  };

  retreveDate = () => {
    axios
      .get(`${BASE_URL}contact-us`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log(res.data.data);
        if (res.status == 200) {
          this.setState({
            loading: false,
          });

          var newData = res.data.data;
          newData.map((dat, index) => {
            dat.index = index + 1;

            // dat.text = (<p className="minmumlines maxpwidth">{dat.text}</p>)

            dat.options = (
              <div className="options">
                <button
                  onClick={() => this.deleteAericle(dat)}
                  className="btn btn-nfc btn-delete ml-1"
                >
                  <i className="fa-solid fa-times"></i>
                  <span>حذف</span>
                </button>
              </div>
            );
          });

          this.setState({
            data: newData,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteAericle = (dat) => {
    Swal.fire({
      // title: "info!",
      text: "هل انت متاكد من الحذف",
      icon: "info",
      confirmButtonText: "تأكيد الحذف",
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire("تم المسح", "", "success");
        this.confirmDeleteAericle(dat);
      }
    });
  };

  confirmDeleteAericle = (dat) => {
    this.setState({
      loading: true,
    });
    let oldData = [...this.state.data];

    let newDate = this.state.data.filter((item) => item.id !== dat.id);
    this.setState({
      data: newDate,
      loadintable: false,
    });

    axios
      .delete(`${BASE_URL}contact-us/${dat.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res.status)
        if (res.status == 200) {
          this.setState({
            loading: false,
          });
          toast.success("تم الحذف بنجاح");
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        toast.error("لم يتم المسح");
        this.setState({
          data: oldData,
        });
        console.log(error.response);
      });
  };

  render() {
    const { loading, rowDate, show } = this.state;
    const datatable = {
      columns: [
        { label: "الترتيب", field: "index" },
        { label: "الاسم", field: "name" },
        { label: "رقم الهاتف", field: "phone" },
        { label: "البريد الإلكتروني", field: "email" },
        { label: "الموضوع", field: "text" },
        { label: "الاختيارات", field: "options" },
      ],
      rows: this.state.data,
    };
    return (
      <div className="main-content">
        <DashboardHeader />

        <div className="dashboard-content">
          <div className="dasboard-box">
            <div className="dashboard_title">
              <h5>تواصل معنا</h5>
            </div>
            <MDBDataTableV5
              hover
              entriesOptions={[5, 10, 15, 20]}
              entries={10}
              pagesAmount={4}
              data={datatable}
              responsive
              searchTop
              searchBottom={false}
            />
          </div>
          {loading ? (
            <div className="loading">
              <div className="circles">
                <div className="circle1"></div>
                <div className="circle2"></div>
                <div className="circle3"></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ContactUs;
