import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import { Accordion, Card, Button } from "react-bootstrap";

class SideNav extends Component {
  render() {
    const { closeSideMenue } = this.props;

    const logOut = () => {
      sessionStorage.removeItem("token");
      window.location.reload();
    };

    return (
      <div>
        <div className="right-sidebar">
          <div className="container">
            <button className="btn btnclose" onClick={closeSideMenue}>
              <i className="fas fa-times"></i>
            </button>
            <div className="logo">
              <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" />
            </div>
            <div className="sideLinkd">
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/">
                    <i className="fa-solid fa-home"></i>
                    <span>الرئيسية</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/articles">
                    <i className="fa-solid fa-note-sticky"></i>
                    <span>المقالات</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/services">
                    <i className="fa-solid fa-boxes"></i>
                    <span>الخدمات</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/historyworks">
                    <i className="fa-solid fa-briefcase"></i>
                    <span>سابقه الاعمال</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contcat-us">
                    <i className="fa-solid fa-envelope"></i>
                    <span>تواصل معنا</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/settings">
                    <i className="fa-solid fa-briefcase"></i>
                    <span>الإعدادات</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="log-out">
              <button className="btn btn-block" onClick={logOut}>
                تسجيل الخروج
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SideNav;
