import React, { Component } from "react";

import { Routes, Route, useParams, useNavigate } from "react-router-dom";

// component
import { BASE_URL, Bublic_URL } from "../component/BASE_URL";
import axios from "axios";

import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import TextEditor from "../component/TextEditor";
import DashboardHeader from "../component/DashboardHeader";

import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import GetUserToken from "../component/GetUserToken";
const token = GetUserToken();

const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class EditHistoryWork extends Component {
  state = {
    title: "",
    description: "",
    type: "history_working",
    date: "",
    image: "",
    loading: false,
  };

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
    this.intiText();
  }

  intiText = (des) => {
    // console.log(des);
    // var newdes = draftToHtml(des);
    let html = `${des}`;
    // console.log(html)
    const contentBlock = htmlToDraft(html);
    // console.log(contentBlock)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    const historyWorkId = this.props.params.id;
    this.retreveData(historyWorkId);
  };

  retreveData = (historyWorkId) => {
    axios
      .get(`${BASE_URL}articles/${historyWorkId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data.data);
          this.setState(
            {
              title: res.data.data.title,
              description: res.data.data.description,
              date: res.data.data.date,
              image: res.data.data.image,
              loading: false,
            },
            () => {
              var decs = this.state.description;
              this.intiText(decs);
            }
          );
        }
      });
  };

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handelUploadPicture = (e) => {
    this.setState({
      loading: true,
    });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    console.log(e.target.files[0]);
    axios
      .post(`${BASE_URL}upload-file`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          this.setState(
            {
              image: res.data,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  loading: false,
                });
              }, 2000);
            }
          );
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    const historyWorkId = this.props.params.id;
    this.setState({
      loading: true,
    });
    if (this.validator.allValid()) {
      const state = { ...this.state };
      console.log(state);
      delete state.loading;
      axios
        .put(`${BASE_URL}articles/${historyWorkId}`, state, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.setState({
              loading: false,
            });
            toast.success("تم التعديل بنجاح");
            this.props.navigate("/historyworks");
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.message}`);
          this.setState({
            loading: false,
          });
        });
    } else {
      toast.error("هناك بعض الاخطاء");
      this.validator.showMessages();
      this.setState({
        loading: false,
      });
      this.forceUpdate();
    }
  };

  render() {
    const { loading, title, date, description, image } = this.state;
    return (
      <div className="main-content">
        <DashboardHeader />

        <div className="dashboard-content">
          <div className="dasboard-box">
            <div className="dashboard_title mb-3">
              <h5>تعديل سابقه الاعمال</h5>
            </div>
            <div className="formadd">
              <form action="" onSubmit={this.submitForm}>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="thetitle">الاسم</label>
                      <input
                        type="text"
                        name="title"
                        id="thetitle"
                        value={title}
                        className="form-control"
                        placeholder="الاسم"
                        onChange={this.handelChange}
                      />
                      <span className="error">
                        {this.validator.message(
                          "title",
                          this.state.title,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="thedate">التاريخ</label>
                      <input
                        type="date"
                        name="date"
                        id="thedate"
                        value={date}
                        className="form-control"
                        placeholder="التاريخ"
                        onChange={this.handelChange}
                      />
                      <span className="error">
                        {this.validator.message(
                          "date",
                          this.state.date,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label htmlFor="thimage">الصورة</label>
                      <input
                        type="file"
                        name="image"
                        id="thimage"
                        className="form-control"
                        placeholder="الصورة"
                        onChange={this.handelUploadPicture}
                      />
                      {/* <span className="error">
                        {this.validator.message(
                          "image",
                          this.state.image_file,
                          "required"
                        )}
                      </span> */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="picture-show">
                      <div className="img_parent">
                        <img src={`${Bublic_URL}${image}`} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label htmlFor="thedescription">الوصف</label>
                      {/* <textarea
                        name="description"
                        id="thedescription"
                        className="form-control"
                        value={description}
                        placeholder="الوصف"
                        onChange={this.handelChange}
                      ></textarea> */}
                      <TextEditor
                        onEditorStateChange={this.onEditorStateChange}
                        editorState={this.state.editorState}
                      />
                      <span className="error">
                        {this.validator.message(
                          "description",
                          this.state.description,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-12">
                    <div className="btn-submit">
                      <button className="btn" type="submit">
                        حفظ
                      </button>
                      <button
                        className="btn btn-danger"
                        type="reset"
                        onClick={() => this.props.navigate("/historyworks")}
                      >
                        الغاء
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {loading ? (
              <div className="loading">
                <div className="circles">
                  <div className="circle1"></div>
                  <div className="circle2"></div>
                  <div className="circle3"></div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditHistoryWork);
