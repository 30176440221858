import React, { Component } from "react";

class StatisticsBox extends Component {
  render() {
    return (
      <>
        <div className="statics_card">
          <div className="card-head">
            <h5>{this.props.title}</h5>
            <i className={this.props.icon}></i>
          </div>

          {this.props.loading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <p>{this.props.statisticCount}</p>
          )}
        </div>
      </>
    );
  }
}

export default StatisticsBox;
