import React, { Component } from "react";

import axios from "axios";

import StatisticsBox from "../component/StatisticsBox";
import DashboardHeader from "../component/DashboardHeader";

import { BASE_URL } from "../component/BASE_URL";
import GetUserToken from "../component/GetUserToken";
const token = GetUserToken();

class Home extends Component {
  state = {
    articleCount: "",
    serviceCount: "",
    historyCount: "",
    contactCount: "",
    loading: true,
  };

  componentDidMount = () => {
    axios
      .get(`${BASE_URL}articles?type=article`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data.data);
          this.setState({
            loading: false,
            articleCount: res.data.data.length,
          });
        }
      });

    axios
      .get(`${BASE_URL}articles?type=service`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data.data);
          this.setState({
            loading: false,
            serviceCount: res.data.data.length,
          });
        }
      });

    axios
      .get(`${BASE_URL}articles?type=history_working`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data.data);
          this.setState({
            loading: false,
            historyCount: res.data.data.length,
          });
        }
      });

    axios
      .get(`${BASE_URL}contact-us`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res.data.data);
        if (res.status == 200) {
          this.setState({
            loading: false,
            contactCount: res.data.data.length,
          });
        }
      });
  };

  render() {
    const { articleCount, serviceCount, historyCount, contactCount, loading } =
      this.state;
    return (
      <div className="main-content">
        <DashboardHeader />
        <div className="dashboard-content">
        <div className="dashboard_title">
          <h5>الرئيسيه</h5>
        </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="dasboard-box">
                <StatisticsBox
                  icon="fas fa-note-sticky"
                  title="عدد المقالات"
                  loading={loading}
                  statisticCount={articleCount}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="dasboard-box">
                <StatisticsBox
                  icon="fas fa-boxes"
                  title="عدد الخدمات"
                  loading={loading}
                  statisticCount={serviceCount}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="dasboard-box">
                <StatisticsBox
                  icon="fas fa-briefcase"
                  title="عدد سابقه الاعمال"
                  loading={loading}
                  statisticCount={historyCount}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="dasboard-box">
                <StatisticsBox
                  icon="fas fa-envelope"
                  title="تواصل معنا"
                  loading={loading}
                  statisticCount={contactCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
