import React, { Component } from "react";

import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../component/BASE_URL";

const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class Login extends Component {
  state = {
    token: "",
    user_name: "",
    password: "",
    loading: false,
  };

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  loginSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        loading: true,
      });
      const state = { ...this.state };
      delete state.loading;
      delete state.token;
      console.log(state);
      axios
        .post(`${BASE_URL}login`, state)
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data.data);
            this.setState({
              token: res.data.data.token,
              loading: false,
            });
            sessionStorage.setItem("token", JSON.stringify(this.state.token));
            toast.success("تم تسجيل الدخول بنجاح");
            this.props.navigate("/");
            window.location.reload();
          }
        })
        .catch((error) => {
          toast.error(`${error.response.data.message}`);
          this.setState({
            loading: false,
          });
        });
    } else {
      toast.error("هناك بعض الاخطاء");
      this.validator.showMessages();
      this.setState({
        loading: false,
      });
      this.forceUpdate();
    }
  };

  render() {
    const { user_name, password, loading } = this.state;
    return (
      <div className="login">
        <img src="./images/login-af.png" className="loginImgBf" alt="" />
        <div className="container">
          <div className="form-parent">
            <form onSubmit={this.loginSubmit} className="login-form">
              <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" />
              </div>
              <h6 className="login-head mt-2">تسجيل الدخول</h6>
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="user_name"
                      id=""
                      className="form-control"
                      placeholder="اسم المستخدم"
                      onChange={this.handelChange}
                      value={user_name}
                    />
                    <span className="error">
                      {this.validator.message(
                        "username",
                        this.state.user_name,
                        "required"
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12">
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      id=""
                      className="form-control"
                      placeholder="كلمة المرور"
                      onChange={this.handelChange}
                      value={password}
                    />
                    <span className="error">
                      {this.validator.message(
                        "password",
                        this.state.password,
                        "required"
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12">
                  <div className="btn-subm">
                    <button type="submit" className="btn btn-block btn-black">
                      تسجيل الدخول
                    </button>
                  </div>
                </div>
              </div>
            </form>

            {loading ? (
              <div className="loading">
                <div className="circles">
                  <div className="circle1"></div>
                  <div className="circle2"></div>
                  <div className="circle3"></div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
